<script lang="ts">
	import { page } from '$app/stores';
	import Icon from '$components/Icon.svelte';
	import type { OAuthResponse, Provider } from '@supabase/supabase-js';
	import { onMount } from 'svelte';
	import { SpinLine } from 'svelte-loading-spinners';
	import { Turnstile } from 'svelte-turnstile';
	import Modal from '../atoms/Modal.svelte';

	export let show: boolean = false;
	export let showRegister: boolean = false;

	$: ({ sb, session } = $page.data);
	$: if (!show) showEmail = false;
	$: isLogin = !showRegister;

	let showEmail = false;

	let name: string = '';
	let email: string = '';
	let password: string = '';
	let invalidInputs = false;
	let loadingAuth: string | null;
	let submitted = false;

	let passwordElement: HTMLInputElement;
	let passwordType: string = 'password';
	let togglePasswordVisibility = () => {
		passwordType = passwordType === 'password' ? 'text' : 'password';
		passwordElement.type = passwordType;
	};

	let captchaToken: string;

	async function handleAuth(provider: string) {
		submitted = provider === 'email';
		if (provider === 'email' && !isLogin && (!name || !password)) return;

		loadingAuth = provider;

		try {
			let response;
			if (provider === 'email') {
				if (isLogin) {
					response = await sb.auth.signInWithPassword({
						email,
						password,
						options: { captchaToken }
					});
				} else {
					response = await sb.auth.signUp({
						email,
						password,
						options: {
							data: { full_name: name },
							captchaToken
						}
					});
				}
			} else {
				response = (await sb.auth.signInWithOAuth({
					provider: provider as Provider,
					options: {
						redirectTo: `${window.location.origin}/api/auth/callback?next=/app`
					}
				})) as OAuthResponse;
			}

			if (response.error) {
				throw response.error;
			}

			if (isLogin || provider !== 'email') {
				window.location.href =
					provider === 'email'
						? window.location.origin + '/auth/redirect-to-app'
						: (response.data as { provider: Provider; url: string }).url;
			} else {
				window.location.href = '/auth/register/verify-email';
			}
		} catch (error) {
			submitted = false;
			invalidInputs = true;
			loadingAuth = null;
			password = '';
			setTimeout(() => {
				invalidInputs = false;
				loadingAuth = null;
			}, 30000);
		}
	}

	function handleKeyDown(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			handleAuth('email');
		}
	}

	async function handleToken(event: CustomEvent<{ token: string }>) {
		({ token: captchaToken } = event.detail);
	}

	function toggleMode() {
		isLogin = !isLogin;
		invalidInputs = false;
		submitted = false;
		loadingAuth = null;
		if (document && document.scrollingElement) document.scrollingElement.scrollTop = 0;
	}

	let mounted = false;

	onMount(() => {
		mounted = true;
	});

	$: if (show && session && mounted) {
		window.location.href = '/auth/redirect-to-app';
	}
</script>

<Modal
	bind:show
	title={`<span class="flex gap-2 items-center font-gigasans"><img
    src="/images/web/loginregister-modal.webp"
	loading="lazy"
    alt=""
    class="size-8 rounded-lg"
/> ${isLogin ? 'Welcome back!' : 'Ready to chat?'}</span>`}
	isResponsive={true}
	hasTopClose={false}
	maxWidth={'md:!w-[550px]'}
	useGlassyBg={false}
>
	<main class="relative flex flex-col gap-4 text-white">
		<div class="flex flex-col gap-8">
			<div>
				<span class="text-gray-300"
					>{isLogin ? 'Sign in to your account with' : 'Create an account with'}</span
				>
				<div class="mb-[22px] mt-[38px] flex flex-row flex-wrap justify-center gap-[8.70px]">
					<button
						class="oauth-btn px-[9px] py-3 font-roboto font-medium text-[#010101] text-opacity-[54%]"
						on:click|stopPropagation={() => handleAuth('google')}
						disabled={loadingAuth === 'google'}
					>
						{#if loadingAuth === 'google'}
							<SpinLine size="18" color="#000000" unit="px" duration="1s" />
						{:else}
							<Icon name="google-color" />Google
						{/if}
					</button>

					<button
						class="oauth-btn !gap-[6px] p-[13px] font-roboto font-medium text-[#010101]"
						on:click|stopPropagation={() => handleAuth('discord')}
						disabled={loadingAuth === 'discord'}
					>
						{#if loadingAuth === 'discord'}
							<SpinLine size="18" color="#000000" unit="px" duration="1s" />
						{:else}
							<Icon name="discord" color="#000000" width="21.69px" height="16.87px" />Discord
						{/if}
					</button>

					<button
						class="oauth-btn px-[9px] py-3 font-roboto font-medium text-[#010101]"
						on:click|stopPropagation={() => handleAuth('apple')}
						disabled={loadingAuth === 'apple'}
					>
						{#if loadingAuth === 'apple'}
							<SpinLine size="18" color="#000000" unit="px" duration="1s" />
						{:else}
							<Icon name="apple" color="#000000" />Apple
						{/if}
					</button>
				</div>
			</div>

			<div class="flex flex-row items-center">
				<div class="h-[1px] w-full bg-white opacity-30" />
				<span class="mt-1 min-w-fit px-2 pb-1 text-sm leading-[125%] opacity-70"
					>Or {isLogin ? 'Sign in' : 'Sign up'} With</span
				>
				<div class="h-[1px] w-full bg-white opacity-50" />
			</div>

			{#if !isLogin && !showEmail}
				<button
					class="v2-btn flex justify-center"
					on:click|stopPropagation={() => (showEmail = true)}
				>
					Sign up with email
				</button>
				<span class="-mt-6 self-end text-xs leading-[150%] opacity-80"
					>We will send a verification link to your email</span
				>
			{:else}
				<div class="flex flex-col gap-2">
					{#if !isLogin}
						<div class="flex flex-col">
							<div class="mb-1.5 text-sm sm:text-base sm:font-medium">Name</div>
							<div class="flex flex-col">
								<input
									bind:value={name}
									on:keydown={handleKeyDown}
									type="text"
									id="name"
									name="name"
									placeholder="Your firstname"
									class="input-text mb-4 {!name && submitted ? 'invalid' : ''}"
								/>
							</div>
							<span
								class="{!name && submitted
									? ''
									: 'hidden'} max-w-[80%] self-end leading-[150%] text-invalid opacity-70"
								>Enter the name you want your AI companions to call you by.</span
							>
						</div>
					{/if}

					<div class="flex flex-col">
						<div class="mb-1.5 font-gigasans text-sm sm:text-base sm:font-medium">
							Email address
						</div>
						<div class="flex flex-col">
							<input
								bind:value={email}
								on:keydown={handleKeyDown}
								type="email"
								autocomplete="email"
								id="email"
								name="email"
								placeholder="you@example.com"
								class="input-text mb-4 {invalidInputs || (submitted && !email) ? 'invalid' : ''}"
							/>
						</div>
						<span
							class="{invalidInputs
								? ''
								: 'hidden'} self-end leading-[150%] text-invalid opacity-70"
							>The email you entered is invalid.</span
						>
					</div>

					<div class="flex flex-col">
						<div class="mb-1.5 font-gigasans text-sm sm:text-base sm:font-medium">Password</div>
						<div class="group relative">
							<input
								bind:this={passwordElement}
								bind:value={password}
								on:keydown={handleKeyDown}
								type="password"
								autocomplete="current-password"
								id="password"
								name="password"
								placeholder="• • • • • • • • • •"
								class="input-text w-full {invalidInputs || (!password && submitted)
									? 'invalid'
									: ''}"
							/>
							<button
								class="absolute right-6 top-[30%] cursor-pointer rounded-full"
								aria-label="Toggle password visibility"
								on:click|stopPropagation={togglePasswordVisibility}
							>
								{#if passwordType === 'password'}
									<div class="hidden group-hover:block">
										<Icon
											name="input-eye-slash"
											width="24px"
											height="24px"
											opacity="0.6"
											color="#9E6FF7"
										/>
									</div>
									<div class="group-hover:hidden">
										<Icon name="input-eye-slash" width="24px" height="24px" opacity="0.6" />
									</div>
								{:else}
									<div class="hidden group-hover:block">
										<Icon
											name="input-eye"
											width="24px"
											height="24px"
											opacity="0.6"
											color="#9E6FF7"
										/>
									</div>
									<div class="group-hover:hidden">
										<Icon name="input-eye" width="24px" height="24px" opacity="0.6" />
									</div>
								{/if}
							</button>
						</div>
						{#if isLogin}
							<span
								class="{invalidInputs
									? 'mt-4'
									: 'hidden'} self-end leading-[150%] text-invalid opacity-70"
								>The password/email you entered is invalid.</span
							>
							<a
								class="font-gigasans {invalidInputs
									? 'leading-[150%] text-invalid'
									: 'mt-4 leading-[125%]'} self-end font-semibold underline-offset-2 hover:underline"
								href="/auth/forgot-password">Forgot Password?</a
							>
						{/if}
					</div>

					<div class="-mb-9 sm:-mb-11">
						<Turnstile
							siteKey="0x4AAAAAAAN7G2fNWAoUOQup"
							theme="light"
							on:turnstile-callback={handleToken}
						/>
					</div>

					<button
						class="v2-btn active ml-auto mt-9 flex min-h-10 items-center justify-center"
						on:click={() => handleAuth('email')}
						disabled={loadingAuth === 'email'}
					>
						{#if loadingAuth === 'email'}
							<SpinLine size="45" color="#ffffff" unit="px" duration="2s" />
						{:else}
							{isLogin ? 'Sign In' : 'Create an account'}
						{/if}
					</button>
				</div>
			{/if}
		</div>

		<div class="text-center font-gigasans">
			<div class="my-12 self-center">
				<div class="">
					<span class="opacity-70"
						>{isLogin ? "Don't have an account yet?" : 'Already have an account?'}</span
					>
					<button
						class="font-semibold text-primary transition hover:underline"
						on:click={toggleMode}>{isLogin ? 'Create an account' : 'Sign in'}</button
					>
				</div>
			</div>
			{#if !isLogin}
				<div class="flex flex-col text-sm">
					<div class="opacity-70">By continuing, you agree to Nastia's</div>
					<div>
						<a class="font-medium hover:underline" href="/terms" target="_blank">Terms of Service</a
						>
						<span class="opacity-70">and</span>
						<a class="font-medium hover:underline" href="/privacy" target="_blank">Privacy Policy</a
						>
					</div>
				</div>
			{/if}
		</div>
	</main>

	<img
		class="fixed -right-[200px] -top-[200px] -z-10 scale-50"
		src="/images/app/auth/scribble-up.png"
		alt=""
		loading="lazy"
	/>
	<img
		class="absolute -bottom-[200px] -left-[170px] -z-10 scale-50"
		src="/images/app/auth/scribble-down.png"
		alt=""
		loading="lazy"
	/>
</Modal>

<style>
	.oauth-btn {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 12px;

		letter-spacing: 0.005em;
		font-weight: 600;
		width: 112px;
		width: 80%;
		height: 44px;

		background: #ffffff;
		border-radius: 1000px;
	}

	.oauth-btn:hover {
		box-shadow: -3px -2px 20px rgba(255, 147, 231, 0.3), 9px 12px 30px rgba(153, 99, 255, 0.3);
		outline: 2px solid rgba(255, 255, 255, 0.6);
	}

	.oauth-btn:disabled {
		box-shadow: none;
		outline: none;
		opacity: 0.2;
	}
</style>
